import React, {useState, useEffect} from 'react';

interface ObfuscatorProps {
  type: 'mail' | 'tel'; // Can i define the type as 'email' | 'phone'
  value: string;
  className?: string;
  children?: React.ReactNode;
}

const Obfuscator:React.FC<ObfuscatorProps> = ({ type, value, className, children }) => {
  const faker = type === 'mail' ? 'email@domain.com' : '06.00.00.00.00'
  const [ displayedValue, setDisplayedValue ] = useState(faker);
  const [ displayedHref, setDisplayedHref ] = useState('');
  const [ obfuscated, setObfuscated ] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayedHref(type === 'mail' ? `mailto:${value}` : `tel:${value}`);
      setDisplayedValue(value);
      setObfuscated(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted
  }, [displayedValue, value, displayedHref, type]);

  return (
    obfuscated ? 
    <div className='blur-sm pointer-events-none'>
      { children ?? <span className={className}>{displayedValue}</span> }
    </div>
    :
        <a href={displayedHref} className={className} >
          { children ??  displayedValue}
        </a>
  );
}

export default Obfuscator;