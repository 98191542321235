import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useFlashMessage } from '../context/FlashMessageContext';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';
import Obfuscator from '../components/Obfuscator';

interface ContactProps {
  className?: string;
}

interface Errors {
  name?: string;
  company?: string;  
  email?: string;
  phone?: string;
  subject?: string;
  message?: string;
}

const ContactView: React.FC<ContactProps> = ({ className }) => {
  const { addFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
      name: '',
      company: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      file: '',
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isSending, setIsSending] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);


  useEffect(() => {
    document.title = "Agantar - Contactez-nous"
  }, [])

  const contactMail = 'contact@agantar.com';
  const contactPhone = '+33 (0)6 58 00 87 65';

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if( value !== '' ) {
      setErrors(prevState => ({
        ...prevState,
        [name]: ''
      }));
    }
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const validate = (): Errors => {
    let tempErrors: Errors = {};   
    if (!formData.name) tempErrors.name = 'Ce champ est obligatoire';    
    if (!formData.email) tempErrors.email = 'Ce champ est obligatoire';
    if (!formData.subject) tempErrors.subject = 'Ce champ est obligatoire';
    if (!formData.message) tempErrors.message = 'Ce champ est obligatoire';
    if (formData.email && !validateEmail(formData.email)) tempErrors.email = 'Adresse email invalide';
    if( formData.phone ) {
      const filtered = formData.phone.replace(/[^0-9+()]/g, '');
      if( !validatePhone(filtered) ) tempErrors.phone = 'Numéro de téléphone invalide';
      else setFormData(prevState => ({
        ...prevState,
        phone: filtered
      }));
    }
    // Additional validation logic
    return tempErrors;
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return re.test(email);
  }

  const validatePhone = (phone: string): boolean => {
    const re = /^[0-9+()]{9,16}$/;
    return re.test(phone);
  }

  // Submit the form
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if( formData.file ) {
      setHoneyPot(true);
      setTimeout(() => {
        addFlashMessage('Votre email à bien été envoyé !', 'success');
        setHoneyPot(false);
        navigate('/', { replace: true });
      }, 2534);
      return;
    }

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);  
      return;
    }
    setErrors({});    
    setIsSending(true);
    
    // EmailJS
    emailjs.send(
      'service_hc9q0gi',
      'template_mu8bj3a',
      formData,
      'qXoTTHBrbvivuGeQT'
    ).then((result) => {
      if( result.text === 'OK' ) {
        addFlashMessage('Votre email à bien été envoyé !', 'success');
        navigate('/', { replace: true });
        setIsSending(false);
      }
    }, (error) => {
      console.log(error.text);
      alert('Une erreur est survenue lors de l\'envoi de l\'email');
      setIsSending(false);
    });

  };

  const label = 'pl-2 pb-1 block text-sm';
  const inputClass = "w-80 mt-1 block w-full p-2 px-3 border rounded-md bg-slate-50 border-black/8 dark:bg-white/10 dark:border-white/10"
  const fieldError = "!border-red-500";

  return (
    <div id="contact" className={`${className} lg:flex gap-x-8`}>
        
      {/* MODAL LOADING */}
      <div className={`${(isSending || honeyPot) ? '' : 'hidden'} absolute top-0 left-0 h-full w-full fixed z-50 flex items-center justify-center bg-slate-100/50 dark:bg-black/50`}>
        <div className="
            shadow flex gap-x-4 items-center px-6 py-6 rounded-lg border 
            dark:bg-gray-950/80 dark:border-white/10 
            bg-slate-200 border-black/5">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" width="20" height="20" className='animate-spin'>
            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
          </svg>
          <span>Envoi en cours</span>
        </div>
      </div>

      {/* CONTACT TEXT */}
      <div className="info">
        <h2>CONTACT</h2>
          <p className='text-justify'>Vous avez un projet ou un besoin pour simplifier la gestion de votre entreprise ?</p>
          <p className='text-justify'>Chez <b>Agantar</b>, nous sommes là pour vous aider. Remplissez le formulaire pour nous contacter et discuter de vos besoins. Ensemble, nous trouverons les solutions adaptées à votre entreprise.</p>
        
        <div className="py-4">
          <div className="flex items-center gap-x-4 mb-2 sm:text-base text-sm">
            <FontAwesomeIcon icon={faEnvelope} className="text-xl"/>
            <Obfuscator type='mail' value={contactMail}/>
          </div>

          <div className="flex items-center gap-x-4 sm:text-base text-sm">
            <FontAwesomeIcon icon={faPhone} className="text-xl"/>
            <Obfuscator type='tel' value={contactPhone}/>
          </div>
        </div>
      </div>

    {/* CONTACT FORM */}
      <div className="glass">
        <form onSubmit={handleSubmit} className="space-y-4">

          <div className="sm:grid grid-cols-2 gap-x-6 gap-y-4 ">

            <div className="form-group">
              <label htmlFor="name" className={`${label} ${errors.name && 'text-red-500 font-bold'}`}>Nom / Prénom*</label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`${inputClass} ${errors.name && fieldError}`}
                disabled={isSending}
              />
              {errors.name && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.name}</span>}
            </div>
            
            <div className="form-group">
              <label htmlFor="company" className={label}>Société</label>
              <input
                id="company"
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                className={inputClass}
                disabled={isSending}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email" className={`${label} ${errors.email && 'text-red-500 font-bold'}`}>Adresse e-mail*</label>
              <input
                id="email"
                name="email"
                type="text"
                value={formData.email}
                placeholder='email@domaine.com'
                onChange={handleChange}
                className={`${inputClass} ${errors.email && fieldError}`}
                disabled={isSending}
              />
              {errors.email && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="phone" className={`${label} ${errors.phone && 'text-red-500 font-bold'}`}>Téléphone</label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={formData.phone}
                placeholder='xx xx xx xx xx'
                onChange={handleChange}
                className={`${inputClass} ${errors.phone && fieldError}`}
                disabled={isSending}
              />
              {errors.phone && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.phone}</span>}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="subject" className={`${label} ${errors.subject && 'text-red-500 font-bold'}`}>Sujet*</label>
            <input 
              id="subject"
              name="subject"
              type="text"
              value={formData.subject}
              onChange={handleChange}
              className={`${inputClass} ${errors.subject && fieldError}`}
              disabled={isSending}
            />
              {errors.subject && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.subject}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="message" className={`${label} ${errors.message && 'text-red-500 font-bold'}`}>Message*</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={`${inputClass} min-h-28 ${errors.message && fieldError}`}
              disabled={isSending}
            />
              {errors.message && <span className="text-xs italic text-red-500 pt-1 pl-1">{errors.message}</span>}
            <div className="text-xs pt-2">Les champs marqués d'une * sont obligatoires.</div>
          </div>

          <input 
            id="file"
            name="file"
            type="text"
            className='bg-transparent hidden'
            value={formData.file}
            onChange={handleChange}
          />

          <div className="text-right">
            <button
              type="submit"
              className="w-fit bg-agantar-blue text-white py-2 px-8 rounded-md hover:bg-agantar-blue-dark"
              disabled={isSending}
            >
              Envoyer
            </button>
          </div>

        </form>
      </div>

    </div>
    
  );
};

export default ContactView;